// var set_col_hights = function(el, extra) {
//     if (typeof extra === "undefined") {
//         extra = 0;
//     }
//
//     if ($(el).css("min-height")) {
//         TweenMax.set($(el), {
//             clearProps: "min-height"
//         });
//     }
//
//     // Get an array of all element heights
//     var elementHeights = $(el).map(function() {
//         return $(this).height();
//     }).get();
//     //console.log(elementHeights);
//     // Math.max takes a variable number of arguments
//     // `apply` is equivalent to passing each height as an argument
//     var maxHeight = Math.max.apply(null, elementHeights);
//
//     // Set each height to the max height
//     $(el).css("min-height", maxHeight + extra + "px");
// };
//
// // how to use...
//
// // $(window).load(function() {
// //   if (Modernizr.mq('(min-width: 767px)')) {
// //     set_col_hights('.feature_box .body');
// //     set_col_hights('.home .output_review .comment');
// //     set_col_hights('.blog_feed_heights');
// //   }
// // });
//
// var do_these_heights = function() {
//     if (Modernizr.mq('(min-width: 767px)')) {
//         if ($(window).width() > 767) {
//             set_col_hights($(".service_block"));
//             set_col_hights($(".single_vacancy_wrap .header_wrap"));
//             set_col_hights($(".single_vacancy_wrap .short_description"));
//         }
//         if ($(window).width() > 991) {
//             set_col_hights($(".do_image_heights"));
//             set_col_hights($(".inner_content_wrap"));
//             set_col_hights($(".service_box .inner_content_wrapper h3"));
//             set_col_hights($(".service_box .inner_content_wrapper .inner_content_desc"));
//         }
//         set_col_hights($(".vacancy_box .job_title_wrap"));
//         set_col_hights($(".vacancy_box .short_description"));
//         // Fix for empty elements getting a height set on them
//         $("p, h1, h2, h3, h4").each(function() {
//             if ($(this).is(':empty')) {
//                 $(this).css("display", "none");
//             }
//         });
//     }
// };
//
// $(window).load(function() {
//     do_these_heights();
// });
//
// $(window).resize(function() {
//     do_these_heights();
// });
