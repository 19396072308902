// var SURGE_GSAP_image_slider = function(setting) {
//     var slider = $(setting.slider);
//     if (slider.length) {
//         var tl = setting.timeline,
//             slides_in_group = setting.slides_in_group,
//             slider_inner = $(".SURGE_gsap_inner", slider),
//             slider_slides = $("> div", slider_inner),
//             slide_buttons = $(".SURGE_slide_buttons", slider),
//             switch_buttons = $(".slide_switch_buttons", slider),
//             number_of_slides_minus_one = slider_slides.length - 1,
//             number_of_slides = slider_slides.length,
//             show_duration = setting.show_duration,
//             animation_duration = setting.animation_duration,
//             do_each_loop = true,
//             pause_on_hover = setting.pause_on_hover,
//             x = 0,
//             switches = "";
//
//         TweenLite.set(slider_inner, { x: "+=0" });
//         //console.log(setting.slides_in_group.min_width);
//         $.each(setting.slides_in_group.min_width, function(key, val) {
//             if (Modernizr.mq('(min-width: ' + parseInt(key, 10) + 'px)')) {
//                 slides_in_group = setting.slides_in_group.min_width[key];
//                 //console.log("found in min_width: "+slides_in_group);
//                 return false;
//             }
//         });
//         $.each(setting.slides_in_group.max_width, function(key, val) {
//             if (Modernizr.mq('(max-width: ' + parseInt(key, 10) + 'px)')) {
//                 slides_in_group = setting.slides_in_group.max_width[key];
//                 //console.log("found in max_width: "+slides_in_group);
//                 return false;
//             }
//         });
//         var number_of_times_to_do_slide_animation = Math.floor(number_of_slides / slides_in_group);
//         $(slider_slides).css("width", 100 / slides_in_group + "%");
//         /*if (setting.type === "main slider") {
//             theSplitText = new SplitText($(".text_box_text_body p", slider).not(".slide_call_to_action p"), { type: "words, chars" });
//             TweenLite.set($(".text_box_text_body p", slider).not(".slide_call_to_action p"), { perspective: 400, autoAlpha: 1.0 });
//             var split_count = $(".text_box_text_body p div").length;
//         }*/
//         if (setting.animation === "slide") {
//             if (number_of_slides % slides_in_group === 0) {
//                 number_of_times_to_do_slide_animation--;
//             }
//         } else {
//             number_of_times_to_do_slide_animation--;
//         }
//         for (var i = number_of_times_to_do_slide_animation; i >= 0; i--) {
//             switches = switches + "<div><span></span></div>";
//         }
//         /*                        console.log("clear");
//                                 console.log("number_of_slides: " + number_of_slides);
//                                 console.log("slides_in_group: " + slides_in_group);
//                                 console.log(number_of_slides % slides_in_group);*/
//         var do_last_slide;
//         if (setting.type === "grouped slider" && setting.animation === "fade" && number_of_slides % slides_in_group !== 0) {
//             // if using fade with grouped
//             //number_of_slides = number_of_slides + 1;
//             switches = switches + "<div></div>";
//             do_last_slide = true;
//             //number_of_times_to_do_slide_animation = number_of_times_to_do_slide_animation + 1;
//         } else {
//             do_last_slide = false;
//         }
//
//
//         //console.log(slides_in_group);
//
//         $("> div:nth-child(" + slides_in_group + "n)", slider_inner).css("background-image", "none");
//         $("> div", slider_inner).last().css("background-image", "none");
//
//         $(".slide_switch_buttons", slider).html(switches);
//         var slide_switch_buttons = $(slider).find(".slide_switch_buttons div");
//         $.each(slider_slides, function(i, e) {
//             if (typeof slides_in_group !== "undefined") {
//                 if (i % slides_in_group === 1 || slides_in_group === 1) {
//                     do_each_loop = true;
//                 } else {
//                     do_each_loop = false;
//                 }
//                 if (setting.animation === "fade" && Math.floor((number_of_slides - 1) / slides_in_group) === x && do_last_slide) {
//                     // if using fade with grouped - go ahead and do the last slide if we have a remainder
//                     do_each_loop = true;
//                 }
//             }
//             if (do_each_loop) {
//                 x++;
//                 var switch_index = x - 1;
//                 //if (setting.animation === "fade") {
//                 tl
//                     .add("start" + x)
//                     .set(slide_switch_buttons, {
//                         background: "none",
//                         className:'-=active'
//                     }, "start" + x)
//                     .to($(slide_switch_buttons).eq(switch_index), animation_duration, {
//                         background: "#FFF",
//                         className:'+=active'
//                     }, "start" + x)
//                     .set($(slide_switch_buttons).find("img"), {
//                         height: 0
//                     }, "start" + x)
//                     .set($(slide_switch_buttons).eq(switch_index).find("img"), {
//                         height: "initial"
//                     }, "start" + x);
//                 //}
//                 if (setting.animation === "fade") {
//                     tl
//                     //.add("start" + x)
//                         .set(slider_inner, {
//                             autoAlpha: 0.2
//                         }, "start" + x)
//                         .to(slider_inner, animation_duration, {
//                             autoAlpha: 1.0
//                         }, "start" + x);
//                 }
//
//
//                 // animations that happen after the full slide has faded in. e.g text animations
//                 /*                    if (setting.type === "main slider") {
//                                         tl
//                                             .add("start_inner_animation" + x)
//                                             .set($(".text_box_text_body", this), {
//                                                 autoAlpha: 0.0,
//                                                 y: "-=40"
//                                             }, "start" + x) // this is purposely set to the last label so that it is hidden before the full slide comes in
//                                             .to($(".text_box_text_body", this), animation_duration, {
//                                                 autoAlpha: 1.0,
//                                                 y: "+=40",
//                                                 ease: Power3.easeOut
//                                             }, "start_inner_animation" + x)
//                                             .staggerFrom($(".text_box_text_body p div div", this), 1.3, {
//                                                 rotationX: 0,
//                                                 x: -250,
//                                                 transformOrigin: "0% 50% -50",
//                                                 scale: 9,
//                                                 autoAlpha: 0.0,
//                                                 ease: Back.easeOut
//                                             }, 0.01, "start_inner_animation" + x + "+=" + animation_duration);
//                                     }*/
//
//
//                 // a label to fill the gap so we can understand what's happening when using currentLabel() later on in the hover function
//                 tl
//                     .add("not_animating_just_showing_slide" + x)
//                     .set({}, {}, "not_animating_just_showing_slide" + x + "+=" + show_duration);
//
//
//                 // start removing slide after the amount of seconds stored in show_duration (from last animation)
//
//                 if (setting.animation === "fade") {
//                     tl
//                         .add("start_removing_slide" + x)
//                         .to(slider_inner, animation_duration, {
//                             autoAlpha: 0.2
//                         }, "start_removing_slide" + x)
//                         .set(slider_inner, {
//                             x: "-=100%"
//                         }, "start_removing_slide" + x + "+=" + animation_duration);
//
//                 }
//                 if (setting.animation === "slide" && x <= number_of_times_to_do_slide_animation) {
//                     tl
//                         .set($(slide_switch_buttons).eq(switch_index), {
//                             background: "#e2e2e2"
//                         }, "start_removing_slide" + x)
//                         .set(slide_switch_buttons, {
//                             background: "#ffffff"
//                         }, "start_removing_slide" + x)
//                         .to($(slide_switch_buttons).eq(switch_index), animation_duration, {
//                             background: "#e2e2e2"
//                         }, "start_removing_slide" + x)
//
//                     .add("start_removing_slide" + x)
//                         .to(slider_inner, animation_duration, {
//                             x: "-=100%"
//                         }, "start_removing_slide" + x);
//                 }
//                 if (setting.animation === "slide" && x === number_of_times_to_do_slide_animation) {
//                     switch_index = switch_index + 1;
//                     tl
//                         .set($(slide_switch_buttons).eq(switch_index), {
//                             background: "#e2e2e2"
//                         }, "start_removing_slide" + x)
//                         .set(slide_switch_buttons, {
//                             background: "#ffffff"
//                         }, "start_removing_slide" + x)
//                         .to($(slide_switch_buttons).eq(switch_index), animation_duration, {
//                             background: "#e2e2e2"
//                         }, "start_removing_slide" + x);
//                 }
//
//             }
//         });
//         //console.log("x: " + x);
//         var hover_slider,
//             all_slide_btns = $.merge(slide_buttons, switch_buttons),
//             current_label,
//             intervaHandle;
//
//         $(slider).hover(function() {
//             if (setting.pause_on_hover) {
//                 if (intervaHandle) {
//                     clearInterval(intervaHandle);
//                     intervaHandle = null;
//                 }
//                 intervaHandle = setInterval(function() {
//                     current_label = tl.currentLabel();
//                     //console.log(current_label);
//                     if (current_label.indexOf("not_animating_just_showing_slide") >= 0) {
//                         clearInterval(intervaHandle);
//                         intervaHandle = null;
//                         tl.pause();
//                     }
//                 }, 200);
//             }
//             if (typeof hover_slider !== "undefined") {
//                 hover_slider.kill();
//             }
//             hover_slider = new TimelineMax();
//             hover_slider.to(all_slide_btns, 1.0, {
//                 autoAlpha: 1.0,
//                 zIndex: 2,
//                 ease: Power3.easeOut
//             });
//         }, function() {
//             if (intervaHandle) {
//                 clearInterval(intervaHandle);
//                 intervaHandle = null;
//             }
//             tl.play();
//             if (typeof hover_slider !== "undefined") {
//                 hover_slider.kill();
//             }
//             hover_slider = new TimelineMax();
//             hover_slider.to(all_slide_btns, 1.0, {
//                 autoAlpha: 0.0,
//                 zIndex: -1,
//                 ease: Power3.easeOut
//             }).set(all_slide_btns, { zIndex: -1 });
//         });
//
//         $(".slide_switch_buttons div, .SURGE_slide_buttons", slider).on("click", function() {
//             var to_time, current_label = tl.currentLabel(),
//                 time, current_label_number = parseInt(current_label.match(/\d+/), 10),
//                 purpusly_undefined; // http://stackoverflow.com/a/9282974/2129024;
//             // no way other than checking the transform percentage directly
//
//             if (intervaHandle) {
//                 clearInterval(intervaHandle);
//                 intervaHandle = null;
//             }
//             //console.log(current_label);
//             if (current_label.indexOf("start_inner_animation") >= 0) {
//                 //only stop if there are active animations.
//                 tl.pause();
//                 time = tl.time();
//             }
//             if ($(this).is(".slide_switch_buttons div")) {
//                 var index = $(this).index();
//                 index = index + 1;
//                 to_time = tl.getLabelTime("not_animating_just_showing_slide" + index);
//                 if (setting.animation === "slide" && $(this).closest(".slide_switch_buttons").find("div").length === index) {
//                     to_time = tl.endTime();
//                 }
//                 TweenMax.to(tl, 0.0, {
//                     time: to_time
//                 });
//                 return false;
//             }
//
//             /*                            var transformMatrix = slider_inner.css("-webkit-transform") ||
//                                             slider_inner.css("-moz-transform") ||
//                                             slider_inner.css("-ms-transform") ||
//                                             slider_inner.css("-o-transform") ||
//                                             slider_inner.css("transform");
//                                         var matrix = transformMatrix.replace(/[^0-9\-.,]/g, '').split(',');
//                                         var translate_x = matrix[12] || matrix[4]; //translate x
//                                         //var translate_y = matrix[13] || matrix[5]; //translate y*/
//
//             var translate_x = $(slider_inner)[0]._gsTransform.xPercent;
//
//             //console.log();
//             var abs_translate_x = Math.abs(translate_x);
//             //console.log(abs_translate_x);
//             var slider_width = $(slider_inner).width();
//
//             /////////////////
//             var time_line_label, max_possible_abs_translate_x, take_one_slide_off_max_abs_translate_x;
//             if ($(this).is(".SURGE_go_forward")) {
//                 if (current_label.indexOf("start") >= 0 || current_label.indexOf("start_inner_animation") >= 0 || current_label.indexOf("not_animating_just_showing_slide") >= 0) {
//                     from_label = "start_removing_slide" + current_label_number;
//                     last_plus_one = parseInt(current_label.match(/\d+/), 10) + 1;
//                     //console.log("last_plus_one: "+last_plus_one);
//                     //console.log("number_of_slides: "+number_of_slides);
//
//                     if (last_plus_one > number_of_slides && setting.animation === "fade") {
//                         tl.tweenFromTo("start", "not_animating_just_showing_slide1");
//                         //console.log("here1");
//                     } else if (last_plus_one > number_of_times_to_do_slide_animation && setting.animation === "slide") {
//                         max_possible_abs_translate_x = number_of_times_to_do_slide_animation * 100;
//                         take_one_slide_off_max_abs_translate_x = max_possible_abs_translate_x - 100;
//                         //console.log("here2");
//                         if (max_possible_abs_translate_x === abs_translate_x) { // its all the way to the end
//                             // back to the start
//                             TweenMax.to(tl, 0.0, {
//                                 time: 0
//                             });
//                             //console.log("here3");
//                         } else {
//                             tl.tweenFromTo(from_label, purpusly_undefined);
//                             //console.log("here4");
//                         }
//                     } else if (setting.type === "grouped slider" && setting.animation === "fade" && last_plus_one > x) {
//                         tl.tweenFromTo("start", "not_animating_just_showing_slide1");
//                         //console.log("here5");
//                     } else {
//                         //console.log("here6");
//                         to_label = "not_animating_just_showing_slide" + last_plus_one;
//                         tl.tweenFromTo(from_label, to_label);
//                     }
//                 }
//             }
//             if ($(this).is(".SURGE_go_back")) {
//                 //if (current_label.indexOf("start") >= 0 || current_label.indexOf("start_inner_animation") >= 0 || current_label.indexOf("not_animating_just_showing_slide") >= 0) {
//                 from_label = "not_animating_just_showing_slide" + current_label_number;
//                 last_minus_one = parseInt(current_label.match(/\d+/), 10) - 1;
//
//                 //console.log("number_of_slides: "+number_of_slides);
//
//                 if (setting.animation === "slide") {
//                     if (current_label_number > 1 && current_label_number !== number_of_times_to_do_slide_animation) {
//                         // must be a middle slide
//                         tl.tweenFromTo("not_animating_just_showing_slide" + current_label_number, "not_animating_just_showing_slide" + last_minus_one, {}); // take it back to the last slide
//                     } else {
//                         if (typeof translate_x === "undefined") { // has not slide yet
//                             //console.log("1");
//                             tl.tweenFromTo(purpusly_undefined, purpusly_undefined, {});
//                         } else if (abs_translate_x === 0) { // has slide but has came back to first slide - so its now set
//                             //console.log("2");
//                             tl.tweenFromTo("start_removing_slide" + number_of_times_to_do_slide_animation, purpusly_undefined, {});
//                         } else if (abs_translate_x < 100) { // has started to slide to the second slide but has not yet got the full way
//                             //console.log("3");
//                             tl.tweenFromTo(tl.time(), 0, {}); // take it back to the start
//                         } else if (current_label_number === number_of_times_to_do_slide_animation) { // it could be the last or the second last slide
//                             //console.log("4");
//                             max_possible_abs_translate_x = number_of_times_to_do_slide_animation * 100;
//                             take_one_slide_off_max_abs_translate_x = max_possible_abs_translate_x - 100;
//
//                             if (max_possible_abs_translate_x === abs_translate_x) { // its all the way to the end
//                                 //console.log("5");
//                                 tl.tweenFromTo(tl.time(), "not_animating_just_showing_slide" + current_label_number, {}); // go back one but keep it on this end loop
//                             } else if (abs_translate_x < max_possible_abs_translate_x && abs_translate_x !== take_one_slide_off_max_abs_translate_x) { // its moving to wards the end but not yet all the way to it
//                                 //console.log("6");
//                                 tl.tweenFromTo(tl.time(), "not_animating_just_showing_slide" + current_label_number, {});
//                             } else if (abs_translate_x === take_one_slide_off_max_abs_translate_x) { // its waiting on the second last slide
//                                 //console.log("7");
//                                 tl.tweenFromTo("not_animating_just_showing_slide" + current_label_number, "not_animating_just_showing_slide" + last_minus_one, {}); // take it back to the last slide
//                             }
//                         }
//                     }
//                 } else { // is fade
//                     if (last_minus_one === 0) {
//                         tl.tweenFromTo("start" + x, purpusly_undefined);
//                     } else {
//                         to_label = "not_animating_just_showing_slide" + last_minus_one;
//                         if (time) {
//                             //console.log("time: " + time + " | to_label: " + to_label);
//                             tl.tweenFromTo(time, to_label, {});
//                         } else {
//                             //var from_label_time = tl.getLabelTime(from_label);
//                             var to_label_time = tl.getLabelTime(to_label);
//
//                             //console.log("from_label_time: " + from_label_time + " | to_label_time: " + to_label_time);
//
//                             // not using this because to sometimes failed to go all the way back.
//                             //tl.tweenFromTo(from_label_time, to_label_time);
//                             /* or this */
//                             /*                                  var from_label_time = tl.getLabelTime(from_label);
//                                                                 var to_label_time = tl.getLabelTime(to_label);
//
//                                                                 var inner_last_from_label_time = tl.getLabelTime("start_inner_animation"+last_minus_one);
//                                                                 var length_of_start_inner_animation = from_label_time - inner_last_from_label_time;
//                                                                 var detuct_amount = length_of_start_inner_animation / 10;
//                                                                 detuct_amount = length_of_start_inner_animation - detuct_amount;
//                                                                 var final = inner_last_from_label_time + detuct_amount;
//
//                                                                 console.log("from_label_time: " + final + " | to_label_time: " + to_label_time);
//                                                                 tl.tweenFromTo(final, to_label_time);
//                                                                 */
//                             TweenMax.to(tl, 0.0, {
//                                 time: to_label_time
//                             });
//                         }
//                     }
//                 }
//             }
//         });
//     }
// };
//
// $(document).ready(function() {
//     var main_slider = new SURGE_GSAP_image_slider({
//         slider: "#SURGE_GSAP_slider1",
//         show_duration: 8,
//         timeline: new TimelineMax({
//             repeat: -1,
//             repeatDelay: 0
//         }),
//         type: "main slider",
//         slides_in_group: 1,
//         pause_on_hover: true,
//         animation: "fade", // if you set this to "slide" animation then please also set up TimelineMax with TimelineMax({ yoyo: true, repeatDelay: what ever the show_duration was, })
//         animation_duration: 0.8
//     });
//
//     var homepage_vacancy_slider = new SURGE_GSAP_image_slider({
//         slider: "#SURGE_GSAP_slider5",
//         show_duration: 8,
//         timeline: new TimelineMax({
//             repeat: -1,
//             repeatDelay: 0
//         }),
//         type: "grouped slider",
//         slides_in_group: {
//             min_width: {
//                 "1200": 3
//             },
//             max_width: {
//                 "1199": 3,
//                 "991": 2,
//                 "767": 1
//             }
//         },
//         pause_on_hover: true,
//         animation: "fade", // if you set this to "slide" animation then please also set up TimelineMax with TimelineMax({ yoyo: true, repeatDelay: what ever the show_duration was, })
//         animation_duration: 0.8
//     });
//
//     // var grouped_slider = new SURGE_GSAP_image_slider({
//     //     slider: "#SURGE_GSAP_slider3",
//     //     show_duration: 999999999999999999,
//     //     timeline: new TimelineMax({
//     //         repeat: -1,
//     //         repeatDelay: 0
//     //     }),
//     //     type: "grouped slider",
//     //     slides_in_group: {
//     //         min_width: {
//     //             "1200": 1
//     //         },
//     //         max_width: {
//     //             "1199": 1,
//     //             "991": 1,
//     //             "767": 1
//     //         }
//     //     },
//     //     pause_on_hover: true,
//     //     animation: "fade", // if you set this to "slide" animation then please also set up TimelineMax with TimelineMax({ yoyo: true, repeatDelay: what ever the show_duration was, })
//     //     animation_duration: 0.8
//     // });
// });
