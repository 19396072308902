// On document ready, initialise our code.
$(function() {
  // Set up dropdown controls
  var $sectorSelect = $('.archive_vacancies_wrapper #filter_sector'),
    $jobTypeSelect = $('.archive_vacancies_wrapper #filter_job_type'),
    $wageSelect = $('.archive_vacancies_wrapper #filter_wage'),
    $locationSelect = $('.archive_vacancies_wrapper #filter_location');
  // Instantiate MixItUp
  $('#Container').mixItUp({
    controls: {
      enable: false // we won't be needing these
    },
    animation: {
      easing: 'cubic-bezier(0.86, 0, 0.07, 1)',
      duration: 600
    }
  });
  $('#archive_job_search').on('click', function() {
    var filterString = $sectorSelect.val() + $jobTypeSelect.val() + $wageSelect.val() + $locationSelect.val();
    $("#Container").mixItUp('filter', filterString);
  });
});

$(window).load(function() {
  var site_url_on_load = window.location.hash;
  if (site_url_on_load.indexOf("#mix-") > -1) {
    var new_filter_string = site_url_on_load.replace('#mix-', '');
    console.log(new_filter_string);
    $("#Container").mixItUp('filter', new_filter_string);
  }
})
