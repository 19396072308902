// //google location suggestions
// var map, autocomplete, places, geocoder;
//
// function initAutocomplete() {
//   // Create the autocomplete object, restricting the search to geographical
//   // location types.
//   autocomplete = new google.maps.places.Autocomplete(
//       /** @type {!HTMLInputElement} */(
//       document.getElementById('location_input')), {
//         types: ['(cities)'],
//         componentRestrictions: {country: 'gb'}
//     });
//     places = new google.maps.places.PlacesService(map);
//
//     autocomplete.addListener('place_changed', fillInAddress);
//
//   // When the user selects an address from the dropdown, populate the address
//   // fields in the form.
//   // autocomplete.addListener('place_changed', fillInAddress);
// }
//
// function fillInAddress() {
//   // Get the place details from the autocomplete object.
//   var place = autocomplete.getPlace();
//   geocoder = new google.maps.Geocoder;
//
//   geocoder.geocode({'placeId': place.place_id}, function(results, status) {
//     if (status === 'OK') {
//       if (results[0]) {
//         console.log(results[0]);
//       } else {
//         window.alert('No results found');
//       }
//     } else {
//       window.alert('Geocoder failed due to: ' + status);
//     }
//   })
// }
//
// // Bias the autocomplete object to the user's geographical location,
// // as supplied by the browser's 'navigator.geolocation' object.
// function geolocate() {
//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(function(position) {
//       var geolocation = {
//         lat: position.coords.latitude,
//         lng: position.coords.longitude
//       };
//       var circle = new google.maps.Circle({
//         center: geolocation,
//         radius: position.coords.accuracy
//       });
//       autocomplete.setBounds(circle.getBounds());
//     });
//   }
// }

// initAutocomplete();



$(".row_year .icon").on("click", function() {
  if ($(this).hasClass("open_me")) {
    TweenMax.to($(this), 0.5, {
      rotation: "180"
    });
    $(this).parent().next().css("display", "block");
    $(this).removeClass("open_me");
    $(this).addClass("close_me");
  } else {
    TweenMax.to($(this), 0.5, {
      rotation: "0"
    });
    $(this).parent().next().css("display", "none");
    $(this).removeClass("close_me");
    $(this).addClass("open_me");
  }
});

$(window).load(function() {
  if ($(window).width() > 767) {
    $(".testimonial_banner").each(function() {
      $(this).find(".icon_wrapper").css("height", $(this).find(".testimonial_text_wrapper").outerHeight(true));
    });
  }
});

// Code to search for a job from outside the Vacancies page
$(window).load(function() {
  var $sectorSelect = $('#filter_sector'),
    $jobTypeSelect = $('#filter_job_type'),
    $wageSelect = $('#filter_wage'),
    $locationSelect = $('#filter_location');

  $('#job_search').on('click', function() {
    var string_to_pass = $sectorSelect.val() + $jobTypeSelect.val() + $wageSelect.val() + $locationSelect.val();
    // start_navigation_away("", "http://egrecruiting.co.uk.88-208-205-107.surgems.co.uk/vacancies/#mix-" + string_to_pass);
  });
});

// Code for job form opening/closing
$(window).load(function() {
  $(".click_to_apply_button").on('click', function() {
    if ($(".job_form_wrap").css("display") == "none") {
      $(".job_form_wrap").css("display", "block");
    } else {
      $(".job_form_wrap").css("display", "none");
    }
  });
});

// Code for staff descriptions
$(window).load(function() {
  var this_data_attr;
  $(".team_members_row .read_more_button").on('click', function() {
    $(".team_member_box_wrap").css("display", "none");
    this_data_attr = $(this).attr("data-attr");
    $("." + this_data_attr).css("display", "block");
    $("." + this_data_attr + " .inner_content_wrapper").css("height", $("." + this_data_attr + " .image_wrapper").outerHeight(true));
  });
});
