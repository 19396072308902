/* Mega menu */
var mega = new TimelineMax(),
  mega_open = function (mega_this) {
    if (!$(mega_this).is(".open")) {
      if (typeof mega !== "undefined") {
        mega.kill();
      }
      var mega_menu_height = $(".mega_dropdown", mega_this).outerHeight(true);      
      mega.add("start")
        .set($(mega_this), {
          className: "+=open"
        })
        .set($("span.parent_text", mega_this), {
          backgroundColor: "rgba(255, 255, 255, 0.88)"
        }, 0)
        .set($("> a, > span, > span a", mega_this), {
          color: "#000"
        }, 0);
      if (Modernizr.mq('(min-width: 992px)')) {
        mega.set($(".mega_dropdown", mega_this), {
          height: "0px",
          autoAlpha: 0.0
        }, 0);

        //custom animation for perceptualreality
        mega.to(".top_banner", 0.25, {
          paddingTop: "50px"
        });
        // end custom animation for perceptualreality
      } else {
        mega.set($(".mega_dropdown", mega_this), {
            height: "0px",
            autoAlpha: 0.0,
            position: "relative",
            top: "0"
          }, 0)
          .set($(mega_this).prev(".close_menu"), {
            y: 125
          }, 0)
          .set($(mega_this).closest(".SURGE_mega_menu"), {
            height: "inherit"
          }, 0)
          .to($(mega_this).prev(".close_menu"), 0.5, {
            y: 21,
            autoAlpha: 1.0,
            display: "block",
            rotation: 180
          }, 0);
      }
      mega.to($(".mega_dropdown", mega_this), 0.5, {
        zIndex: "9",
        height: mega_menu_height,
        autoAlpha: 1.0,
        ease: Expo.easeOut
      }, 0);
    }
  },
  mega_close = function (mega_this) {
    //custom animation for perceptualreality
    if (Modernizr.mq('(min-width: 992px)')) {
      TweenMax.set(".top_banner", {
        paddingTop: "0px"
      });
    }
    // end custom animation for perceptualreality

    $(mega_this).removeClass("open");


    if (typeof mega !== "undefined") {
      //console.log("chris if");
      mega.kill();
    } else {
      //console.log("chris else");
    }
    mega = new TimelineMax({
      delay: 0.0
    });
    mega.set($.merge($("span.parent_text, > a, > span, > span a, .mega_dropdown", mega_this), $(mega_this).prev(".close_menu")), {
      clearProps: "all"
    });

  };


var count_this = 0,
  mega_this,
  mobile_menu_height = $("#SURGE_mega_menu_1").height(), //.outerHeight(true),
  document_width;

$(document).ready(function () {
  document_width = $(window).width();
});



var mega_events = function () {
  //console.log("here");
  if (document_width !== $(window).width()) { // resize was being fired on scroll on chrome mobile browser (address bar moving down) - here we are protecting out code from this happening
    document_width = $(window).width();
    TweenMax.set($("#SURGE_mega_menu_1"), {
      clearProps: "height,position"
    });
    mobile_menu_height = $("#SURGE_mega_menu_1").height(); //.outerHeight(true);       

    // console.log("mobile_menu_height: " + mobile_menu_height);
    // TweenMax.set($("#SURGE_mega_menu_1"), { height: mobile_menu_height });

    mega_close(".is_dropdown");
    //if (!navigator.userAgent.match(/Mobi/)) {
    if (Modernizr.mq('(min-width: 992px)')) {
      $('.is_dropdown').off("click.mega_events"); // resets if this runns more than once
      $('.is_dropdown').unbind("mouseenter");
      $('.is_dropdown').unbind("mouseleave");
      $('.is_dropdown')
        .mouseenter(function () {
          mega_this = this;
          //if (count_this === 0) {
            //mega_open(mega_this);
          //}
          //count_this++;
          //alert("mouseenter");
          //if (Modernizr.mq('(min-width: 992px)')) {
          // code that will put the dropdown below its hovered parent li...
          if ($(mega_this).css("position") === "relative") { // if this css is set then the dropdowns will be directly aligned below the menu item so our js needs to work with that!
            $(".mega_filler_links a", mega_this).css("white-space", "nowrap"); // set nowrap so that the width can be calculated as it collapses in on itself without
            var padding_left = parseInt($(".mega_dropdown > li", mega_this).css("padding-left"), 10);
            var dropdown_with = $(".mega_dropdown > li", mega_this).outerWidth(true);
                  
            // set the outer dropdown to the with of it nested col
            $(".mega_dropdown", mega_this).css("width", dropdown_with + "px");

            var menu_space_availble_for_dropdown = $(mega_this).closest(".SURGE_mega_menu").width() - $(mega_this).position().left;
            if (menu_space_availble_for_dropdown < dropdown_with) {
              var dropdown_minus_left_position = dropdown_with - menu_space_availble_for_dropdown + padding_left;
              $(".mega_dropdown", mega_this).css("left", "-" + dropdown_minus_left_position + "px");
            }
          } else { // no relative css so the dropdowns will be like footcareuk. wide dropdowns.
            var position = $(mega_this).position(); 
            var rightOffset = $(mega_this).closest(".SURGE_mega_menu").width() - (position.left + $(mega_this).width());
            //console.log("rightOffset: "+rightOffset);
            //console.log("position.left: "+position.left);
            var difference = (position.left - rightOffset) + ($(mega_this).width() / 2);

            difference = Math.abs(difference); // removing negative -
            //console.log("difference: "+difference);

            // top: 100% !important; 
            // left: 0 !important; 
            // right: 0 !important; 
            // bottom: 0 !important; 
            if (difference < 150) {
              //$(".mega_dropdown", mega_this).css({ left: "17%", right: "17%" });
              $(".mega_dropdown", mega_this).css({
                left: "0",
                right: "0",
                top: "99%"
              });
            } else if (position.left > rightOffset) {
              //$(".mega_dropdown", mega_this).css({ left: "28%", right: "2.3%" });
              $(".mega_dropdown", mega_this).css({
                left: "0",
                right: "0",
                top: "99%"
              });
            }
            //console.log("menu width: "+ $(".mega_dropdown", this).width());
          }
          //}
          mega_open(mega_this);
        })
        .mouseleave(function () {
          //alert("mouseleave");
          mega_this = this;
          mega_close(mega_this);
        });
    } else {
      $('.is_dropdown').unbind("mouseenter");
      $('.is_dropdown').unbind("mouseleave");
      $('.is_dropdown').off("click.mega_events"); // resets if this runns more than once
      $('.is_dropdown .parent_text').on("click.mega_events", function () {
        mega_close(".is_dropdown");
        mega_this = $(this).closest(".is_dropdown");
        if (!$(mega_this).is(".open")) {
          mega_open(mega_this);
          goToByScroll(mega_this, 40, 0.8);
        }
      });
    }
  }
};
mega_events();
$(window).on("resize", mega_events);

var mobile_menu;
$('.close_menu, .navbar-toggle').on("click", function () {
  mega_close($(".is_dropdown"));
  if ($(this).is(".navbar-toggle")) {
    if (Modernizr.mq('(max-width: 991px)')) {
      if ($(this).hasClass("collapsed")) {
        if (typeof mobile_menu !== "undefined") {
          mobile_menu.kill();
        }
        mobile_menu = new TimelineMax({
          delay: 0.0
        });
        mobile_menu.set($("#SURGE_mega_menu_1"), {
            height: "0",
            margin: "-23px 0",
            position: "relative"
          }, 0)
          .to($("#SURGE_mega_menu_1"), 1.0, {
            height: mobile_menu_height,
            margin: "0",
            autoAlpha: 1.0,
            ease: Expo.easeOut
          });
        $(this).removeClass("collapsed");
      } else {
        if (typeof mobile_menu !== "undefined") {
          mobile_menu.kill();
        }
        mobile_menu = new TimelineMax({
          delay: 0.0
        });
        mobile_menu.to($("#SURGE_mega_menu_1"), 1.0, {
          height: "0",
          margin: "-23px 0",
          autoAlpha: 0.0,
          ease: Expo.easeOut
        });
        $(this).addClass("collapsed");
      }
    }
  }
  if ($(this).is(".close_menu")) {
    goToByScroll("html", 0, 0.8);
  }
});


$(window).load(function () { // wait for images to load
  // set cols the same height
  var highest = null,
    hi = 0,
    collection = $(),
    process_collection = false,
    remove_hrs = true;
  if (Modernizr.mq('(min-width: 992px)')) {
    $(".mega_col").each(function () {
      if ($(this).next().is("hr")) {
        collection = $.merge(collection, $(this));
        $(this).css({
          //"padding-right": "0",
          "background": "none"
        });
        process_collection = true;
      } else {
        collection = $.merge(collection, $(this));
      }
      if (process_collection) {
        $(collection).each(function (i, el) {
          if ($("> *", this).first().is("h4")) {
            remove_hrs = false;
          }
          ////
          var h = $(this).outerHeight(true);
          if (h > hi) {
            hi = h;
          }
        });
        if (remove_hrs) {
          $("> hr:first-of-type", collection).remove();
          hi = hi - 25;
        }
        if ($(this).closest(".is_dropdown").css("position") !== "relative") { // if this css is set then the dropdowns will be directly aligned below the menu item so our js needs to work with that!
          $(collection).height(hi);
        }
        // resets
        collection = $();
        process_collection = false;
        remove_hrs = true;
        hi = 0;
      }
    });
  } else {
    $(".mega_dropdown > hr").remove();
  }
  // remove end hr(s)
  //$(".mega_col hr").last().remove();
  $(".mega_dropdown hr:last-child").remove();
});


/////////////////////////////////////
//move mega menu up the page on mobile
// var moved = false;
// var move_mega_menu = function() {
//     if ($(window).width() < 992 && !moved) {
//         $(".mobile_section").append($("#SURGE_mega_menu_1"));
//         $('select.notranslate > option:contains(Select Language)').text('Language');
//         moved = true;
//     }
//     if ($(window).width() > 991 && moved) {
//         $("#wrapper_for_SURGE_mega_menu_1").append($("#SURGE_mega_menu_1"));
//         $('select.notranslate > option:contains(Language)').text('Select Language');
//         moved = false;
//     }
// }
// $(window).resize(move_mega_menu);
// move_mega_menu();


/////////////////////////////////////

/*// set active class on links that are seen as active.
var check_link_match = function(the_link, class_target) {
  var is_active = false;
  if ($(the_link).attr("href") === site_url) {
    $(class_target).addClass("home_page");
  }
  if ($(the_link).attr("href") === site_url && window.location.pathname === "/") {
    $(class_target).addClass("active_page");
  }
  if ($(the_link).attr("href").indexOf(window.location.pathname) > -1 && window.location.pathname !== "/") {
    $(class_target).addClass("active_page");
    is_active = true;
  }
  if ($(the_link).text().toLowerCase().indexOf("contact") > -1) {
    $(class_target).addClass("contact_page");
  }
  if (is_active === true) {
    return true;
  } else {
    return false;
  }
};*/
/*$(".SURGE_mega_menu").each(function(){
  $(".parent_li", this).each(function(){
    check_link_match($(".parent_text a", this), this);
    $(".mega_dropdown a", this).each(function(){
      if (check_link_match(this, this)) { // also force active class on parent link if a sub link has been found to be active
        $(this).closest(".parent_li").addClass("active_page");
      }
    });
  });
});*/
// END set active class on links that are seen as active.

// set top level label wrappers to the size of the text within them so that they dont jiggle about on hover (when the text goes bold it will make the text bigger)
// this will stop the text from pushing on the next one
//                 if (Modernizr.mq('(min-width: 992px)')) {
//                     $(".SURGE_mega_menu .parent_li").each(function() {
//                         $(this).width($(this).width());
//                         $(".parent_text", this).attr("data-original_width", $(".parent_text", this).width());
//                     });
//                     $('.parent_li') // here we are takeing the increase of width from the padding on the text element to stop it overflowing its parent
//                         .mouseenter(function() {
//                             var current_side_padding = $(".parent_text", this).css("padding-left").replace("px", ""), // will be the same as padding right...
//                                 hover_parent_text_width = $(".parent_text", this).width(),
//                                 origin_width = $(".parent_text", this).attr("data-original_width"),
//                                 added_with = hover_parent_text_width - origin_width,
//                                 added_with_divided = added_with / 2;
//                             new_side_padding = current_side_padding - added_with_divided;
//                             $(".parent_text", this).css({ "padding-left": new_side_padding + "px", "padding-right": new_side_padding + "px" });
//                         })
//                         .mouseleave(function() {
//                             TweenMax.set($(".parent_text", this), { clearProps: "all" });
//                         });
//                 }
/* END mega menu */
